import React, { useCallback, useEffect, useState } from "react"
import Layout from "components/member/signUp/layout.js"
import * as styles from "./terms.module.css"
import TermScroll from "components/member/signUp/termScroll.js"
import json from "contents/term.json"
import { navigate, Link } from "gatsby"
import CheckBox from "components/tools/checkBox.js"
import api from "apis"
import { term } from "utils/data"
import { getTermContents, setSort, arrayEdit, textEdit } from "utils"
import dompurify from "dompurify"
import InputTitle from "components/tools/InputTitle"
const SignUpTermsPage = () => {
  const Idx = [1, 7, 8] // 이용약관, 개인정보 수집 이용 동의, 마케팅 활용 동의

  const [termList, setTermList] = useState([])

  useEffect(() => {
    ;(async () => {
      const terms = await api.getTermDetail(arrayEdit(Idx, "%23"))

      const result = terms.map(v => {
        return {
          key: v.kind,
          term_no: v.term_no,
          title: v.title,
          contents: getTermContents(v.TermContent),
        }
      })
      setTermList(setSort(result, "key"))
    })()
  }, [])

  const allCheck = useCallback(({ target: { checked } }) => {
    document.getElementsByName("termcheck").forEach(checkbox => {
      checkbox.checked = checked
    })
  }, [])

  const onConfirm = () => {
    const [usePeach, usePersonal] = document.getElementsByName("termcheck")

    if (!(usePeach?.checked && usePersonal?.checked)) {
      document.getElementsByClassName("errMsg")[0].textContent =
        "피치서가 이용약관과 개인정보 수집 이용에 모두 동의해 주세요."
    } else {
      // 다음페이지로 이동
      navigate("/member/signUp/single")
    }
  }
  return (
    <Layout title={"회원가입"}>
      <div className={"colContainer"}>
        <div className={styles.fullConsent}>
          <div>
            <CheckBox
              id={"allCheck"}
              className={"circleCheckBox"}
              onClick={allCheck}
            />
            &nbsp;약관 전체동의
          </div>
          <div className={styles.dotCon}>
            <div
              className={styles.colon}
              style={{
                fontSize: 31,
                marginRight: 12,
                bottom: 40,
                fontWeight: "bold",
                display: "inline",
                // marginLeft: 10,
              }}
            >
              <div style={{ paddingTop: "11px" }}>.</div>
              <div>.</div>
            </div>
            <div>
              피치서가 이용약관, 개인정보 수집 이용동의, 프로모션 정보 수신에
              <br />
              모두 동의합니다.
            </div>
          </div>
        </div>

        {termList?.map(({ contents, title, idx }, i, { length }) => (
          <div key={`term_content_${i}`}>
            <TermScroll
              title={`${title}${i === length - 1 ? "(선택)" : "(필수)"}`}
            >
              <CheckBox
                name={"termcheck"}
                id={`termcheck_${i}`}
                className={"circleCheckBox"}
              />
              {/* <div style={{ marginBottom: "10px" }}>{title}</div> */}
              {typeof window !== "undefined" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(contents),
                  }}
                />
              ) : null}
            </TermScroll>
            {i === 1 ? (
              <>
                <div
                  className={"errMsg"}
                  style={{
                    paddingTop: "15px",
                    marginLeft: "13px",
                    fontWeight: "400",
                  }}
                />
                <hr />
              </>
            ) : null}
          </div>
        ))}
        <div className={styles.BtnContainer}>
          <div
            className={"cancelBtn"}
            onClick={() => {
              navigate("/login")
            }}
          >
            취소
          </div>
          <div className={"confirmBtn"} onClick={onConfirm}>
            확인
          </div>
        </div>
        <div className={styles.LinkContainer}>
          10개 이상의 대량 아이디 발급이 필요하세요? &nbsp;
          <div onClick={() => navigate("/member/signUp/group")}>
            단체 회원가입 문의하기
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SignUpTermsPage
