import React from "react"
import * as styles from "components/member/signUp/layout.module.css"
import Logo from "components/tools/logo.js"
import { navigate } from "gatsby"
import { term } from "utils/data"

// const number = term.keys()
// console.log(number.next().value)

const Layout = props => (
  <div>
    <div className={styles.header}>
      {/* <img src={sampleimg} alt={"titleLogo"} /> */}
      <div onClick={() => navigate(`/login`)}>
        <Logo w={152.53} h={34} navCancel={true} />
      </div>
      <div style={{ marginLeft: "20px" }}>{props.title}</div>
    </div>
    <div className={styles.container}>{props.children}</div>
    <div className={styles.footer} style={{ marginTop: "20px" }}>
      <div>
        <div className={styles.link} onClick={() => navigate("/term/intro/")}>
          피치서가 소개
        </div>
        <div
          className={styles.link}
          onClick={() => navigate(`/term/?number=${Array.from(term)[0][0]}`)}
        >
          이용약관
        </div>
        <div
          className={styles.link}
          onClick={() => {
            navigate(`/term/?number=${Array.from(term)[2][0]}`)
          }}
        >
          개인정보처리방침
        </div>
        <div
          className={styles.link}
          onClick={() => {
            navigate("/member/signUp/group")
          }}
        >
          파트너쉽 제휴
        </div>
      </div>
      <div>
        <div onClick={() => navigate(`/login`)}>
          <Logo w={105.39} h={23.21} navCancel={true} />
        </div>

        <div style={{ fontWeight: "400" }}>
          Copyright Peach Seoga korea ALL Rights reserved.
        </div>
      </div>
    </div>
  </div>
)

export default Layout
