import React from "react"
import * as styles from "components/member/signUp/termScroll.module.css"

const termScroll = props => {
  const [checkbox, subContent, content] = props.children

  const main = props.title?.slice(0, props.title?.lastIndexOf("("))
  const sub = props.title?.slice(props.title?.lastIndexOf("("))

  const subTextStyle = {
    display: "inline",
    color: sub === "(필수)" ? "#E95C63" : "#7C7C7C",
    fontSize: "12px",
  }

  return (
    <div>
      <div className={styles.title}>
        {checkbox}
        &nbsp;{main}
        <div style={subTextStyle}>{sub}</div>
      </div>
      <div className={styles.scroll}>
        {subContent}
        {content}
      </div>
    </div>
  )
}

export default termScroll
